
const PrivilegeCardOrderProjectCard     = 1 //购买项目为免单卡
const PrivilegeCardOrderProjectMerchant = 2 //购买项目为商家入驻
const PrivilegeCardOrderProjectAgent    = 3 //购买项目为代理

export function project2Tag(project){
    let str = "";
    switch (parseInt(project)){
        case PrivilegeCardOrderProjectCard:
            str = "免单卡";
            break;
        case PrivilegeCardOrderProjectMerchant:
            str = "商家入驻";
            break;
        case PrivilegeCardOrderProjectAgent:
            str = "申请代理";
            break
    }
    return str;
}

const PrivilegeCardOrderStatusWait     = 1 //未支付
const PrivilegeCardOrderStatusPaid     = 2 //已支付
const PrivilegeCardOrderStatusFinished = 4 //交易完成

export function status2Tag(project){
    let str = "";
    switch (parseInt(project)){
        case PrivilegeCardOrderStatusWait:
            str = "未支付";
            break;
        case PrivilegeCardOrderStatusPaid:
            str = "已支付";
            break;
        case PrivilegeCardOrderStatusFinished:
            str = "已完成";
            break
    }
    return str;
}